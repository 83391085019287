<!--批量导入试卷-->
<template>
  <div class="pageContol formCom">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">我的资源</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()">我的试题</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">批量导入试卷</a>
        </span>
      </div>
      <div class="framePage-body addtestPaper">
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-form
              ref="ruleForm"
              :model="ruleForm"
              :rules="rules"
              label-width="12rem"
              class="commodity-form"
            >
              <el-form-item label="培训类型" prop="Trainingtype" class="form-item">
                <tree
                  ref="tree"
                  @eventBtn="childBack"
                  modal
                  :ruleForm="ruleForm"
                  @clearParams="clearParams"
                  size="small"
                  type
                  typeStu
                />
              </el-form-item>
              <el-form-item label="通过分数" prop="paperScore" class="form-item">
                <el-input-number size="small" v-model="ruleForm.paperScore" :min="1" :max="200"></el-input-number>
              </el-form-item>
              <el-form-item label="下载模板" class="form-item">
                <el-button @click="downloadExcel">下载</el-button>
              </el-form-item>
              <el-form-item label="上传试题" prop="fileName">
                <el-upload
                  v-model="ruleForm.fileName"
                  v-if="!ruleForm.fileName"
                  class="upload-demo upload-btn"
                  :action="actionUrl"
                  :on-error="handleError"
                  :on-success="handleSuccess"
                  :on-change="uploadChange"
                  :show-file-list="false"
                  :auto-upload="false"
                >
                  <el-button size="small" class="bgc-bv">选取文件</el-button>
                </el-upload>
                <span style="color:#f46173" v-else>
                  {{ ruleForm.fileName }}
                  <a @click="reomveExl" style="color: #5bb5ff">删除</a>
                </span>
              </el-form-item>
              <el-form-item label=" " class="form-item form-item-hint">
              <div style="color: red;">
                <p>温馨提示，请在导入前仔细阅读以下提示信息:</p>
                <p>1.导入的文件格式为zip压缩包；</p>
                <p>
                    2.需要导入的.XLS 和 .XLSX格式的文件，文件名称为试卷名称，例如：试卷名称.XLS；
                </p>
                <p>
                    3.将需要导入的试卷选中后直接压缩，不要将试卷放在文件夹内再压缩文件夹;
                </p>
                <p>
                    4.单个压缩包大小不可超过10M，超过10M后，每10M左右一个压缩包，然后分别导入即可。
                </p>
              </div>
              </el-form-item>
              <el-form-item label="是否发布在平台销售：" prop="salesState" class="form-item form">
                <div>
                  <el-radio-group v-model="ruleForm.salesState">
                    <el-radio :label="20">是</el-radio>
                    <el-radio :label="10">否</el-radio>
                  </el-radio-group>
                </div>
              </el-form-item>
              <template v-if="importTheResults">
                <el-form-item label="导入结果：" class="form-item" style="margin-bottom: 0px;">
                  <div class="df studentNum">
                    <span>试卷总数量:{{ totalNum }}套;</span>
                    <span>成功:{{ correctNum }}套;</span>
                    <span>失败:{{ errorNum }}套;</span>
                  </div>
                </el-form-item>
                <el-form-item style="margin-bottom: 0px;">
                  <el-progress
                    v-if="Number(progressVal) > 0 && Number(progressVal) < 100"
                    style="margin: 10px 0;width: 200px;"
                    :text-inside="true"
                    :stroke-width="16"
                    :percentage="progressVal"
                  ></el-progress>
                </el-form-item>
                <el-form-item>
                  <div>
                    <el-button
                      class="bgc-bv"
                      style="height: 35px"
                      size="mini"
                      :disabled="!excelUrl"
                      @click="doExportError"
                      >导出错误数据</el-button
                    >
                  </div>
                  <div style=" color: #dd1d35" v-if="errorNum != 0">
                    有导入失败试卷，请导出错误数据，将导入失败试卷调整后重新上传
                  </div>
                </el-form-item>
              </template>
              <div class="form-btn">
                <el-button class="bgc-bv" @click="formCancel">返回</el-button>
                <el-button class="bgc-bv" @click="formSave('ruleForm')">提交审核</el-button>
              </div>
            </el-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import UploadPic from "@/mixins/UploadPic";
import DatePicker from "@/mixins/DatePicker";
import Form from "@/mixins/Form";
import tree from "@/components/treePopup";
export default {
  name: "addTestPaper",
  mixins: [UploadPic, DatePicker, Form],
  components: {
    tree
  },
  data() {
    return {
      actionUrl: "",
      ruleForm: {
        id: null,
        paperScore: 60,
        excelUrl: "",
        salesState: 10,
        Trainingtype: "",
        fileName: ""
      },
      datatreeList: [],
      props: {
        value: "id",
        label: "label"
      },
      //是否上传文件
      updateFile: false,
      rules: {
        paperScore: [
          { required: true, message: "请输入通过分数", trigger: "blur" }
        ],
        salesState: [
          { required: true, message: "请选择销售状态", trigger: "blur" }
        ],
        Trainingtype: [
          { required: true, message: "请选择培训类型", trigger: "change" }
        ],
        fileName: [{ required: true, message: "请上传试卷", trigger: "blur" }]
      },
      params: {},
      // 进度条百分比
      progressVal: 0,
      // 导入 - 试卷总数量
      totalNum: "",
      // 导入 - 试卷失败数量
      errorNum: "",
      // 导入 - 试卷成功数量
      correctNum: "",
      // 导出 - 错误的url地址
      excelUrl: "",
      // 显示导入结果
      importTheResults: false,
    };
  },
  computed: {},
  methods: {
    // 培训类型回调
    childBack(params) {
      this.params = { ...params };
    },
    clearParams() {
      this.params = {};
    },
    init() {
      const query = this.$route.query;
      this.getList();
      this.ruleForm.id = query.id;
      if (query.id) {
        this.getData(query.id);
      }
    },
    // 保存
    formSave(formName = "ruleForm") {
      const el = this.$refs[formName];
      el.validate(valid => {
        if (valid) {
          this.doAjax();
        } else {
          this.$message({
            type: "warning",
            message: "请输入必填项"
          });
        }
      });
    },
    // 培训类型
    getList() {
      this.$post("/sys/category/train-type/tree").then(ret => {
        if (ret.status == 0) {
          this.datatreeList = ret.data;
        }
      });
    },
    downloadExcel() {
      /**
       * 下载模板
       * @param param 参数
       */
      let link = document.createElement("a");
      link.style.display = "none";
      link.href = "/static/paper.xls";
      link.setAttribute("download", "paper.xls");
      document.body.appendChild(link);
      link.click();
    },
    doAjax() {
      let params = {
        paperScore: this.ruleForm.paperScore,
        excelUrl: this.ruleForm.excelUrl,
        salesState: this.ruleForm.salesState,
        paperOrgin: 20 //强行设置待审核，未来根据审核功能调整
      };
      if (this.params.trainFormId) {
        params.trainTypeId = this.params.trainFormId;
      }
      if (this.params.levelFormId) {
        params.trainLevelId = this.params.levelFormId;
      }
      if (this.params.occFormId) {
        params.occupationId = this.params.occFormId;
      }
      if (this.params.postFormId) {
        params.postId = this.params.postFormId;
      }
      if (this.params.industryFormId) {
        params.industryId = this.params.industryFormId;
      }
      if (this.ruleForm.excelUrl == "") {
        this.$message.warning("请先上传试题");
        return false;
      }
      
      this.$post("/biz/paper/import/batchId", {excelUrl:this.ruleForm.excelUrl})
        .then((res) => {
          this.doProgress(res.data);
          this.importTheResults = true
          this.$post("/biz/paper/insertPaperQuestionDispatch", {...params,batchId:res.data})
        })
        .catch(err => {
          return;
        });
      
    },
    // 实时获取进度条
    doProgress(id) {
      this.$post("/biz/paper/import/progress", { batchId: id }).then(
        (ret) => {
          if (!ret.data.status) {
            this.progressVal = ret.data.progress;
            setTimeout(() => {
              this.doProgress(ret.data.batchId);
            }, 1000);
          } else {
            this.totalNum = ret.data.totalNum;
            this.errorNum = ret.data.errorNum;
            this.correctNum = ret.data.correctNum;
            this.progressVal = ret.data.progress;
            this.excelUrl = ret.data.excelUrl;
            if (ret.data.excelUrl) {
              this.$message({
                type: "error",
                message: ret.data.message,
              });
            } else {
              this.$message({
                type: "success",
                message: "导入成功",
              });
              this.formCancel("1");
            }
          }
        }
      );
    },
    // 导出错误数据
    doExportError() {
      window.open(this.excelUrl);
    },
    formCancel(isRefresh) {
      this.$router.push({
        name: "testPaperList",
        params: {
          refresh: isRefresh == "1" ? true : false,
          refName: "first",
          isAdd: isRefresh == "1" ? true : false,
        }
      });
    },
    /**
     *
     */
    uploadChange(file) {
      let size = file.size / 1024 / 1024;
      // let fileName = file.name.split(".");
      let extension = file.name.substring(file.name.lastIndexOf(".") + 1);
      const iszip = extension === "zip";
      if (!iszip) {
        this.$message.error("只能上传后缀是.zip的文件");
        return;
      }
      if (size > 10) {
        this.$message.error("文件大小不能超过10M");
        return;
      }
      this.ruleForm.fileName = file.name;
      let formData = new FormData();
      formData.append("folder ", "QUESTION");
      formData.append("file ", file.raw);
      formData.append("fileType ", extension);
      this.$Postformat("/sys/upload", formData)
        .then(result => {
          this.ruleForm.excelUrl = result.data.fileKey;
          this.$refs["ruleForm"].clearValidate("fileName");
        })
        .catch(() => {
          setTimeout(() => {
            this.$message({
              type: "warning",
              message: "保存失败"
            });
          }, 300);
        });
      this.$forceUpdate();
    },
    reomveExl() {
      this.ruleForm.fileName = "";
      this.ruleForm.excelUrl = "";
      this.actionUrl = "";
      this.$forceUpdate();
    }
  }
};
</script>
<style lang="less">
.upload-btn {
  height: 40px;
  .el-upload {
    height: 40px !important;
    border: none !important;
  }
}
.addtestPaper {
  .el-input--small .el-input__inner {
    height: 32px;
    line-height: 32px;
  }
}
</style>
